import Thumbnail1 from './images/blog1.jpg';
import Thumbnail2 from './images/blog2.jpg';
import Thumbnail3 from './images/blog3.jpg';
import Thumbnail4 from './images/blog4.jpg';

 export const DUMMY_POSTS = [
    {
        id: '1',
        thumbnail: Thumbnail1,
        category: 'education',
        title: 'This is the title of the very first post on this blog.',
        desc: "Lorem imsum dolor sdnaskdihh d dud ododw wodjwo o  wed woeoww w oweowo woewo  woowo woejoew o eu owowowowpwo wo euow uoed wo e wop u woe dow uowo owed oweoweow edo wedowoed woeoweodw uw.",
        authorID: 3
    },
    {
        id: '2',
        thumbnail: Thumbnail2,
        category: 'art',
        title: 'Welcome to India.',
        desc: "Lorem imsum dolor sdnaskdihh d dud ododw wodjwo o  wed woeoww w oweowo woewo  woowo woejoew o eu owowowowpwo wo euow uoed wo e wop u woe dow uowo owed oweoweow edo wedowoed woeoweodw uw.",
        authorID: 2
    },
    {
        id: '3',
        thumbnail: Thumbnail3,
        category: 'education',
        title: 'This is the title of the very first post on this blog.',
        desc: "Lorem imsum dolor sdnaskdihh d dud ododw wodjwo o  wed woeoww w oweowo woewo  woowo woejoew o eu owowowowpwo wo euow uoed wo e wop u woe dow uowo owed oweoweow edo wedowoed woeoweodw uw.",
        authorID: 3
    },
    {
        id: '4',
        thumbnail: Thumbnail4,
        category: 'education',
        title: 'This is the title of the very first post on this blog.',
        desc: "Lorem imsum dolor sdnaskdihh d dud ododw wodjwo o  wed woeoww w oweowo woewo  woowo woejoew o eu owowowowpwo wo euow uoed wo e wop u woe dow uowo owed oweoweow edo wedowoed woeoweodw uw.",
        authorID: 4
    },
]