import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Login = () => {
  const [userData, setUserData] = useState({
  
    email: '',
    password: ''
  
  }) 

const changeInputHandler = (e) => {
  e.preventDefault();
  setUserData(prevState => {
    return {...prevState,[e.target.name]: e.target.value}
   })
  
}

const handleSubmit = (e) => {
  e.preventDefault();
  // Handle form submission logic here (e.g., validation, API call)
}

  return (
    <section className='login'>
<div className='container'>
<h2>Sign In</h2>
<form onSubmit={handleSubmit} className='form login__form'>
  <p className='form__error-message'>This is an error message </p>
   <input type='text' placeholder='Email' name='email' value={userData.email}
   onChange={changeInputHandler} autoFocus/>
   <input type='password' placeholder='Password' name='password' value={userData.password}
   onChange={changeInputHandler} />
   <button type='submit' className='btn primary'> Register </button>
</form>
<small>If you don't have an account ? <Link to='/register'>Sing Up</Link></small>
</div>
    </section>
  )
}

export default Login
