import React, { useState } from 'react'
import { Link } from 'react-router-dom';

const Register = () => {
  const [userData, setUserData] = useState({
    name: '',
    email: '',
    password: '',
    password2: ''
  }) 

const changeInputHandler = (e) => {
  e.preventDefault();
  setUserData(prevState => {
    return {...prevState,[e.target.name]: e.target.value}
   })
  
}

const handleSubmit = (e) => {
  e.preventDefault();
  // Handle form submission logic here (e.g., validation, API call)
}

  return (
    <section className='register'>
<div className='container'>
<h2>Sign Up</h2>
<form onSubmit={handleSubmit} className='form register__form'>
  <p className='form__error-message'>This is an error message </p>
  <input type='text' placeholder='Full Name' name='name' value={userData.name}
   onChange={changeInputHandler} />
   <input type='text' placeholder='Email' name='email' value={userData.email}
   onChange={changeInputHandler} />
   <input type='password' placeholder='Password' name='password' value={userData.password}
   onChange={changeInputHandler} />
   <input type='password' placeholder='Confirm Password' name='password2' value={userData.password2}
   onChange={changeInputHandler} />
   <button type='submit' className='btn primary'> Register </button>
</form>
<small>Already have an account ? <Link to='/login'>Sing In</Link></small>
</div>
    </section>
  )
}

export default Register